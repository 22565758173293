import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["menu"]

    connect() {
        // let dropdown = document.getElementsByClassName('dropdown');
        let menu = this.menuTarget;

        document.addEventListener('click', function (event) {

            if (document.getElementsByClassName('dropdown')[0] != event.target.parentNode && document.getElementsByClassName('dropdown')[0].parentElement != event.target.parentNode) {
                if (!menu.classList.contains('hidden')) {
                    menu.classList.add('hidden');
                }
            }
        });
    }

    toggle() {
        let menu = this.menuTarget;

        if (menu.classList.contains('hidden')) {
            menu.classList.remove('hidden');
        } else {
            menu.classList.add('hidden');
        }
    }
}